<template>
	<div>
		<v-switch
			:loading="loading"
			:value="isAccesibilityOn"
			:label="`Activer les outils d'accessibilité :  ${isAccesibilityOn === true ? 'Activé' : 'Désactivé'}`"
			@click="handleSwitchClick"
			color="black"
			readonly
		/>
		<v-snackbar v-model="snackbar.show" color="#15c39a" :timeout="3000" left>
			{{ snackbar.text }}
		</v-snackbar>

		<div v-if="dialog" class="text-center">
			<v-dialog v-model="dialog" max-width="500px">
				<v-card>
					<v-card-title class="headline"> Mise à jour des paramètres d'accessibilité </v-card-title>
					<v-card-text>
						<p>Pour appliquer les changements d'accessibilité, veuillez recharger la page.</p>
						<p>
							Les outils d'accessibilité seront {{ isAccesibilityOn ? 'activés' : 'désactivés' }} après le rechargement.
						</p>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="primary" text @click="onClickCloseDialogBtn">Recharger maintenant</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AccesibilitySwitch',

	data() {
		return {
			loading: false,
			dialog: false,
			isAccesibilityOn: null,
			snackbar: {
				show: false,
				text: '',
			},
		};
	},
	methods: {
		async handleSwitchClick() {
			this.loading = true;
			if (this.isAccesibilityOn === true) await this.turnOffAccesibility();
			else if (this.isAccesibilityOn === false) await this.turnOnAccesibility();
			this.loading = false;
		},

		async turnOnAccesibility() {
			console.log('turnOnAccesibility');
			window.localStorage.setItem('accessibility_enabled', true);
			this.isAccesibilityOn = true;
			this.dialog = true;
			this.snackbar = {
				show: true,
				text: "Les outils d'accessibilité ont été activés",
			};
		},
		async turnOffAccesibility() {
			window.localStorage.setItem('accessibility_enabled', false);
			this.isAccesibilityOn = false;
			this.dialog = true;
			this.snackbar = {
				show: true,
				text: "Les outils d'accessibilité ont été désactivés",
			};
		},
		onClickCloseDialogBtn() {
			this.dialog = false;
			window.location.reload();
		},
	},
	mounted() {
		const accessibilityEnabled = window.localStorage.getItem('accessibility_enabled');
		this.isAccesibilityOn = accessibilityEnabled === 'true' ? true : false;
	},
};
</script>

<style scoped lang="scss"></style>
